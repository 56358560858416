import { gql } from '@apollo/client'

export const plansFind = gql`
  query plansFind($category: PlanCategory, $limit: Int, $offset: Int, $excludedPlanIds: [String!]) {
    plans {
      find(limit: $limit, offset: $offset, category: $category, excludedPlanIds: $excludedPlanIds) {
        total
        hasMore
        items {
          id
          title
          ownerUserId
          ownerUser {
            firstName
          }
          currentUserRsvp
          maxCapacity
          capacity {
            maxCapacity
            currentCapacity
            atCapacity
          }
          type
          isVisibilityOpen
          timezone
          place {
            venue
            address
          }
          channelId
          dates {
            text
          }
          startDateOnly
          startTimeOnly
          startTimeText
          endTimeText
          imageUrl
          planMembers(limit: 3) {
            total
            items {
              user {
                fullName
                imageUrl
              }
            }
          }
          friendsInPlan {
            items {
              id
              firstName
              imageUrl
            }
          }
          wasSeen
        }
      }
    }
  }
`
