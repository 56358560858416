import './ChannelPlace.scss'
import { Plan, RsvpType } from '../../../__generated__/graphql'

const ChannelPlace = ({ plan }: { plan: Plan }) => {
  // If request to join is disabled, show venue or nothing
  if (plan.enableRequestToJoin && plan.currentUserRsvp !== RsvpType.Accepted) {
    const getMessage = (rsvpType: RsvpType) => {
      switch (rsvpType) {
        case RsvpType.Pending:
          return {
            title: 'request to join to learn more',
            description: "location unlocks once you're approved",
          }
        case RsvpType.Declined:
          return {
            title: "this one's staying secret",
            description: "this plan isn't accepting new guests",
          }
        default:
          return {
            title: 'gotta join to get the spot',
            description: 'request to join to learn more',
          }
      }
    }
    const message = getMessage(plan.currentUserRsvp as RsvpType)
    if (!message) return null

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <i className="bi bi-geo-alt ChannelPlace__link__icon"></i>
        <div>
          <h5>{message.title}</h5>
          <p>{message.description}</p>
        </div>
      </div>
    )
  }

  // Location not available
  if (!plan.place) {
    return null
  }

  // Location available, user joined
  return (
    <div className="ChannelPlace">
      <a
        className="ChannelPlace__link"
        href={`https://maps.google.com/?q=${encodeURI(plan.place.venue + ' ' + plan.place.address)}`}
        target="_blank"
      >
        <i className="bi bi-geo-alt ChannelPlace__link__icon"></i>
        <div className="ChannelPlace__link__text">
          <h5>{plan.place.venue}</h5>
          <p className="ChannelPlace__link__text__address">{plan.place.address}</p>
        </div>
      </a>
    </div>
  )
}

export { ChannelPlace }
