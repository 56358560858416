import { Plan, PlanDate } from '../__generated__/graphql'
import { dayjs, dayjsUtc } from '../util/dayjs-util'

export function getStartTime(startTimeText: string) {
  const date = dayjsUtc(startTimeText, 'M/D/YYYY, h:m A')
  return date.format('h:mm A')
}

export const createDateFromPlan = (plan: Plan): PlanDate[] => {
  if (!plan.startDateOnly) return []
  const utcDate = dayjs(plan.startDateOnly)
  const actualDate = utcDate.tz(plan.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
  const text = actualDate.format('MMM D, YYYY')
  return [
    {
      text,
      voteUserIds: [],
      __typename: 'PlanDate',
    },
  ]
}

export const createTimeFromPlan = (plan: Plan): string | null => {
  if (plan.startTimeOnly && dayjs(plan.startTimeOnly).isValid()) {
    const utcTime = dayjs(plan.startTimeOnly)
    const actualTime = utcTime.tz(plan.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
    return actualTime.format('h:mm A')
  }
  if (plan.startTimeText && dayjs(plan.startTimeText).isValid()) {
    return getStartTime(plan.startTimeText)
  }
  return null
}
