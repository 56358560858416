import { Plan, RsvpType } from '../../__generated__/graphql'
import { createDateFromPlan, createTimeFromPlan } from '../../util/get-start-time'
import { getTimezoneText } from '../../util/get-timezone-text'

export const PlanCardDateTimeLocation = ({ plan }: { plan: Plan }) => {
  const venue = plan.place?.venue

  const dates = plan.dates ? [...plan.dates] : createDateFromPlan(plan)
  const timeText = createTimeFromPlan(plan)
  const dateText = dates?.length === 1 ? dates[0].text : null

  let timezoneText
  if (dates?.length === 1 && timeText && plan.timezone) {
    timezoneText = getTimezoneText(plan.timezone)
  }

  // e.g. "Sunday, Jul 30 · 6:45 PM"
  let dateAndTimeText = null
  if (dates && dates.length === 1) {
    const timeText = createTimeFromPlan(plan)
    dateAndTimeText = [dateText, timeText].filter((item) => !!item).join(' · ')
  }

  const currentUserJoinedPlan = plan.currentUserRsvp === RsvpType.Yes || plan.currentUserRsvp === RsvpType.Maybe
  if (plan.title.includes('Show & Tell')) {
    console.log(timeText, plan.startDateOnly, dateText, venue)
  }
  return (
    <>
      {!dateText && !timeText && !venue && <p className="Caption">Details TBD</p>}
      {!timeText && !dateText && venue && <p className="Caption">Date TBD</p>}
      {timeText && !dateText && <p className="Caption">Date TBD • {timeText}</p>}
      {!timeText && dateText && <p className="Caption">{dateText}</p>}
      {dateText && timeText && dateAndTimeText && (
        <p className="Caption">
          {dateAndTimeText}
          {timezoneText && ` ${timezoneText}`}
        </p>
      )}
      {currentUserJoinedPlan && venue && <p className="Caption">{venue}</p>}
    </>
  )
}
