import { Dispatch, SetStateAction } from 'react'
import './ChannelDates.scss'
import { Plan } from '../../../__generated__/graphql'
import { getTimezoneText } from '../../../util/get-timezone-text'
import { createTimeFromPlan } from '../../../util/get-start-time'
import { PlanDate } from './PlanDate/PlanDate'
import { PlanTime } from './PlanTime/PlanTime'

export const ChannelDates = ({
  plan,
}: {
  plan: Plan
  setSignUpAndRsvpModalIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  let timeText = createTimeFromPlan(plan)
  if (timeText && plan.timezone) {
    const timezoneText = getTimezoneText(plan.timezone)
    if (timezoneText) {
      timeText = `${timeText} ${timezoneText}`
    }
  }

  const invalidTimeText = timeText === null
  return (
    <div className="ChannelDates">
      {plan.startDateOnly ? (
        <PlanDate date={plan.startDateOnly} timeText={timeText} timezone={plan?.timezone} />
      ) : (
        timeText && !invalidTimeText && <PlanTime timeText={timeText} />
      )}
    </div>
  )
}
