import { useQuery } from '@apollo/client'
import './ChannelMembers.scss'
import { Plan, PlanUser, RsvpType, User } from '../../../__generated__/graphql'
import { useNavigate } from 'react-router-dom'
import { planUsersFindPlanMembers } from '../../../graphql/queries/plan-users-find-plan-members'
import { useSelector } from 'react-redux'
import { imageSizer } from '../../../util/image-sizer'
import { PreviousView } from '../../../types/previous-view.enum'
import { planUsersFindPlanMembersByRsvpType } from '../../../graphql/queries/plan-users-find-plan-members-by-rsvp-type'
import React, { useState } from 'react'
import { ModalOrDrawer } from '../../ModalOrDrawer/ModalOrDrawer'
import { ManageChannelMembers } from './ManageChannelMembersModal/ManageChannelMembers'
import { AttendanceCaption } from './AttendanceCaption'

export function ChannelMembers({ plan }: { plan: Plan }) {
  const navigate = useNavigate()
  const user = useSelector((state: any) => state.user as User)
  const [manageMembersModalIsOpen, setManageMembersModalIsOpen] = useState(false)

  // yes, maybe, & accepted planUsers
  const {
    loading: planUsersLoading,
    error: planUsersError,
    data: planUsersData,
  } = useQuery(planUsersFindPlanMembers, {
    variables: { planId: plan.id },
  })
  // pending planUsers
  const { data: pendingPlanUsersData } = useQuery(planUsersFindPlanMembersByRsvpType, {
    variables: { planId: plan.id, rsvp: RsvpType.Pending },
  })
  const isManagingRequests = (plan.ownerUserId === user.id && plan.enableRequestToJoin) || false

  if (planUsersLoading) return <></>
  if (planUsersError) return <></>

  if (!planUsersData.planUsers) return <></>

  const planUsers: PlanUser[] = planUsersData.planUsers.findPlanMembers.items
  const sortedPlanUsers = [...planUsers].sort((a, b) => {
    if (a.user.id === user?.id) return -1 // current user goes first, no matter what
    if (a.user.id === plan.ownerUserId && b.user.id !== user?.id) return -1 // plan owner goes after current user
    if (b.user.id === plan.ownerUserId) return 1 // plan owner goes before any user other than current user
    return 0
  })
  const pendingPlanUsers = pendingPlanUsersData?.planUsers?.find?.items || []
  const acceptedPlanUsers = sortedPlanUsers.filter((planUser) => planUser.rsvp === RsvpType.Accepted)

  const previousView = PreviousView.planDetailPage

  const handleMemberProfile = (userId: string) => {
    navigate(`/user/${userId}`, { state: { previousView, planId: plan.id } })
  }

  const planMembersBlock = (planUsers: PlanUser[]) => {
    return (
      <div className="ChannelMembers__members-container Mt-4">
        {planUsers?.map((planUser: PlanUser) => (
          <button
            className="ChannelMember"
            key={planUser.user.id}
            onClick={() => handleMemberProfile(planUser.user.id)}
          >
            <div className="ChannelMember__container">
              <div>
                <img
                  className="ChannelMember__container__profile-picture"
                  src={planUser.user.imageUrl ? imageSizer({ url: planUser.user.imageUrl, width: 300 }) : undefined}
                  alt={planUser.user.firstName || 'Profile Picture'}
                />
                <p className="ChannelMember__container__member-name Mt-2">
                  {planUser.user.id === user?.id ? 'You' : planUser.user.firstName}
                </p>
                {memberCaption(isManagingRequests, planUser, user?.id, plan.ownerUserId) && (
                  <p className="Caption">{memberCaption(isManagingRequests, planUser, user?.id, plan.ownerUserId)}</p>
                )}
              </div>
              {planUser.rsvp && [RsvpType.Yes, RsvpType.Accepted, RsvpType.Maybe].includes(planUser.rsvp) && (
                <div className="ChannelMember__container__rsvp-icon Flex Flex--align-center Flex--centered">
                  <i
                    className={
                      [RsvpType.Yes, RsvpType.Accepted].includes(planUser.rsvp) ? 'bi bi-check' : 'bi bi-question'
                    }
                  ></i>
                </div>
              )}
            </div>
          </button>
        ))}
      </div>
    )
  }

  const { yesCount, maybeCount, acceptedCount } = calculateRsvpCounts(planUsers)

  const hasPotentialToJoin = plan.enableRequestToJoin && !planUsers.some((planUser) => planUser.user.id === user.id)

  return (
    <div className="ChannelMembers">
      {isManagingRequests && (
        <div className="Flex Flex--space-between Flex--align-center">
          <h2 className="Mb-3">RSVP Requests</h2>
          <button onClick={() => setManageMembersModalIsOpen(true)} className="Button-secondary Button-medium">
            Manage
          </button>
        </div>
      )}
      {(isManagingRequests || !hasPotentialToJoin) && (
        <AttendanceCaption
          plan={plan}
          user={user}
          yesCount={yesCount}
          maybeCount={maybeCount}
          acceptedCount={acceptedCount}
        />
      )}
      {isManagingRequests && (
        <>
          {!pendingPlanUsers.length && !acceptedPlanUsers.length && <p className="Gray-text">No requests yet</p>}
          {pendingPlanUsers.length > 0 && (
            <div className="Mt-4 Mb-2">
              <h3 className="Pink-text">{`${pendingPlanUsers.length} Pending`}</h3>
              {planMembersBlock(pendingPlanUsers)}
            </div>
          )}
          {acceptedPlanUsers.length > 0 && (
            <div className="Mt-4">
              <h3>{`${acceptedPlanUsers.length} Accepted`}</h3>
              {planMembersBlock(acceptedPlanUsers)}
            </div>
          )}
        </>
      )}
      {!isManagingRequests && planMembersBlock(sortedPlanUsers)}
      {manageMembersModalIsOpen && (
        <ModalOrDrawer
          setIsOpen={setManageMembersModalIsOpen}
          content={<ManageChannelMembers plan={plan} setManageMembersModalIsOpen={setManageMembersModalIsOpen} />}
          height="fill"
        />
      )}
    </div>
  )
}

function calculateRsvpCounts(planUsers: PlanUser[]): { yesCount: number; maybeCount: number; acceptedCount: number } {
  return planUsers.reduce(
    (acc, planUser) => {
      if (planUser.rsvp === RsvpType.Yes) acc.yesCount++
      else if (planUser.rsvp === RsvpType.Maybe) acc.maybeCount++
      else if (planUser.rsvp === RsvpType.Accepted) acc.acceptedCount++
      return acc
    },
    { yesCount: 0, maybeCount: 0, acceptedCount: 0 },
  )
}

function memberCaption(isManagingRequests: boolean, planUser: PlanUser, userId: string, ownerUserId: string): string {
  if (isManagingRequests) return ''
  if (planUser.userId === ownerUserId && planUser.userId !== userId) return 'Creator'
  if (planUser.rsvp === RsvpType.Yes) return 'Yes'
  if (planUser.rsvp === RsvpType.Accepted) return 'Yes'
  if (planUser.rsvp === RsvpType.Maybe) return 'Maybe'
  return ''
}
