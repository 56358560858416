import Header from '../Header/Header'
import { useSelector } from 'react-redux'
import { Profile } from './Profile/Profile'
import { Helmet } from 'react-helmet-async'

export const ProfilePage = () => {
  const userLoaded = useSelector((state: any) => state.userLoaded as boolean)

  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  if (!userLoaded) {
    return null
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {!isMobile && <Header />}
      <Profile />
    </>
  )
}
