import './UserDetails.scss'
import { User, UserConnectionStatus } from '../../../__generated__/graphql'
import Instagram from '../../../assets/svg/Instagram.svg'
import Linkedin from '../../../assets/svg/Linkedin.svg'
import Twitter from '../../../assets/svg/Twitter.svg'
import { useState, useEffect, useRef } from 'react'
import { AddInfo } from './AddInfo'
import { InfoType } from '../Profile/Profile'
import { Interest, InterestsPills } from '../../InterestsPills/InterestsPills'
import { FavoriteSpots } from '../../FavoriteSpots/FavoriteSpots'

export const UserDetails = ({
  userProfile,
  isMyId,
  me,
  interests,
  setEditProfile,
  setInfoType,
}: {
  userProfile: User
  isMyId: boolean
  me: User
  interests: Interest[]
  setEditProfile: React.Dispatch<React.SetStateAction<boolean>>
  setInfoType: React.Dispatch<React.SetStateAction<InfoType>>
}) => {
  const [showFullBio, setshowFullBio] = useState(false)
  const descriptionRef = useRef<HTMLParagraphElement>(null)

  const userBio = userProfile.bio || ''
  const userHasSocials = userProfile.instagram || userProfile.twitter || userProfile.linkedIn

  useEffect(() => {
    if (descriptionRef.current) {
      const isOverflowing = userBio.length < 250
      setshowFullBio(isOverflowing)
    }
  }, [userBio])

  const handleShowMore = () => {
    setshowFullBio(true)
  }

  const displayAddress = () => {
    const neighborhood = userProfile.neighborhood
    const city = userProfile.city
    const country = userProfile.country

    if (neighborhood) {
      return `${neighborhood}`
    } else if (city && country) {
      return `${city}, ${country}`
    } else if (city) {
      return `${city}`
    } else if (country) {
      return `${country}`
    } else {
      return null
    }
  }

  const address = displayAddress()
  const addedMeAsPieFriend =
    userProfile.userConnectionStatus === UserConnectionStatus.AddedMe ||
    userProfile.userConnectionStatus === UserConnectionStatus.BothAdded

  const handleOpenInstagram = () => {
    window.open(`https://www.instagram.com/${userProfile.instagram}`)
  }

  const hanleOpenTwitter = () => {
    window.open(`https://www.twitter.com/${userProfile.twitter}`)
  }

  const handleOpenLinkdin = () => {
    window.open(`https://www.linkedin.com/in/${userProfile.linkedIn}`)
  }

  const missingInfo =
    ((userProfile.instagram === null || userProfile.instagram === '') &&
      (userProfile.twitter === null || userProfile.twitter === '') &&
      (userProfile.linkedIn === null || userProfile.linkedIn === '')) ||
    userProfile.city === null ||
    userProfile.city === '' ||
    userProfile.userInterests.length === 0

  const onInfoClick = (infoType: InfoType) => {
    setInfoType(infoType)
    setEditProfile(true)
  }

  return (
    <div className="UserProfile Flex">
      <div className="UserProfile__container Flex">
        <div className="Flex">
          {isMyId ? (
            <a href={me.imageUrl || ''} target="_blank" rel="noopener noreferrer">
              <img
                className="UserProfile__container__picture"
                src={me.imageUrl || ''}
                alt={me.fullName || 'Profile Picture'}
              />
            </a>
          ) : (
            <a href={userProfile.imageUrl || ''} target="_blank" rel="noopener noreferrer">
              <img
                className="UserProfile__container__picture"
                src={userProfile.imageUrl || ''}
                alt={userProfile.firstName || 'Profile Picture'}
              />
            </a>
          )}
        </div>
        <div>
          <h1>{userProfile.fullName}</h1>
          <p
            className={`Secondary-text UserProfile__container__bio ${
              showFullBio ? 'UserProfile__container__bio--full-description' : ''
            }`}
            ref={descriptionRef}
          >
            {userBio}
          </p>
          {!showFullBio && userBio.length > 250 && (
            <button className="UserProfile__container__bio__see-more-button" onClick={handleShowMore}>
              See more
            </button>
          )}
          {userHasSocials && (
            <div className="Flex UserProfile__container__socials Mt-2 Mt-2">
              {userProfile.instagram && (
                <div className="Flex Flex--align-center Mr-2" onClick={handleOpenInstagram}>
                  <img src={Instagram} alt={'Instagram Icon'} />
                  <p className="Ml-1">@{userProfile.instagram}</p>
                </div>
              )}
              {userProfile.twitter && (
                <div className="Flex Flex--align-center Mr-2" onClick={hanleOpenTwitter}>
                  <img src={Twitter} alt={'Twitter Icon'} />
                  <p className="Ml-1">@{userProfile.twitter}</p>
                </div>
              )}
              {userProfile.linkedIn && (
                <div className="Flex Flex--align-center" onClick={handleOpenLinkdin}>
                  <img src={Linkedin} alt={'LinkedIn Icon'} />
                  <p className="Ml-1">@{userProfile.linkedIn}</p>
                </div>
              )}
            </div>
          )}
          {address !== null && (
            <div className="Flex Mt-2">
              <i className="bi bi-geo-alt-fill"></i>
              <p className="Ml-1">{address}</p>
            </div>
          )}
          {addedMeAsPieFriend && (
            <div className="Flex Flex--align-center Mt-2">
              <i className="bi bi-star-fill"></i> <p className="Ml-1">Added you as a Pie Friend</p>
            </div>
          )}
          {interests.length > 0 && isMyId && (
            <div>
              <h2 className="Mt-6 Mb-2">Your Interests</h2>
              <InterestsPills interests={interests} isDisabled />
            </div>
          )}
          <FavoriteSpots user={isMyId ? me : userProfile} />
        </div>
      </div>
      {isMyId && missingInfo && (
        <div className="Mt-6">
          <h2 className="Mb-6">Complete your profile</h2>
          <div className="UserProfile__add-info">
            {userProfile.userInterests.length === 0 && (
              <AddInfo text="Add Interests" icon="bi-stars" onAddInfoClick={() => onInfoClick('interests')} />
            )}
            {userProfile.favoriteSpots.length === 0 && (
              <AddInfo text="Add Fav Spots" icon="bi-heart" onAddInfoClick={() => onInfoClick('favoriteSpots')} />
            )}
            {(userProfile.city === null || userProfile.city === '') && (
              <AddInfo text="Add City" icon="bi-geo-alt" onAddInfoClick={() => onInfoClick('city')} />
            )}
            {(userProfile.bio === null || userProfile.bio === '') && (
              <AddInfo text="Add Bio" icon="bi-info-circle" onAddInfoClick={() => onInfoClick('bio')} />
            )}
            {(userProfile.instagram === null || userProfile.instagram === '') && (
              <AddInfo text="Add Instagram" icon="bi-instagram" onAddInfoClick={() => onInfoClick('instagram')} />
            )}
            {(userProfile.twitter === null || userProfile.twitter === '') && (
              <AddInfo text="Add Twitter" icon="bi-twitter" onAddInfoClick={() => onInfoClick('twitter')} />
            )}
            {(userProfile.linkedIn === null || userProfile.linkedIn === '') && (
              <AddInfo text="Add LinkedIn" icon="bi-linkedin" onAddInfoClick={() => onInfoClick('linkedIn')} />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
