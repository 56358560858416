import { Plan, User } from '../../../__generated__/graphql'
import React from 'react'

export const AttendanceCaption: React.FC<{
  plan: Plan
  user: User
  yesCount: number
  maybeCount: number
  acceptedCount: number
}> = ({ plan, user, yesCount, maybeCount, acceptedCount }) => {
  const countsText = formatCountText(yesCount, maybeCount, acceptedCount)
  const spotsLeftText = calculateSpotsLeft(plan, user, yesCount, acceptedCount)

  return (
    <>
      <h2 className="Mt-4">RSVPs</h2>
      <p className="Caption">
        {countsText}
        {spotsLeftText && <span className="Caption Pink-text">{spotsLeftText}</span>}
      </p>
    </>
  )
}

function formatCountText(yesCount: number, maybeCount: number, acceptedCount: number): string {
  const combinedYesAcceptedCount = yesCount + acceptedCount
  let response = `${combinedYesAcceptedCount} Yes`
  if (maybeCount > 0) {
    response += ` • ${maybeCount} Maybe`
  }
  return response
}

function calculateSpotsLeft(plan: Plan, user: User, yesCount: number, acceptedCount: number): string {
  const maxCapacity = plan.capacity?.maxCapacity
  const atCapacity = plan.capacity?.atCapacity
  const isOwner = plan.ownerUserId === user.id

  if (isOwner && maxCapacity && !atCapacity) {
    const spotsLeft = maxCapacity - (yesCount + acceptedCount)
    return ` ${spotsLeft} ${spotsLeft === 1 ? 'spot' : 'spots'} left`
  }

  return ''
}
