import type { JSX } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import './PlanDetailPage.scss'
import PlanDetail from '../Channel/PlanDetail'
import Loader from '../Loader/Loader'
import Header from '../Header/Header'
import Error from '../Error/Error'
import { MorePlans } from '../MorePlans/MorePlans'
import { useLazyQuery } from '@apollo/client'
import { PlansQueries } from '../../__generated__/graphql'
import { getPlan } from '../../graphql/queries/plans-plan'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { channelIdToPlanId } from '../../util/channel-id-to-plan-id'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { feedJoinedPathname } from '../../constants/path-names'
import { ChannelChat } from '../ChannelChat/ChannelChat'
import { PreviousView } from '../../types/previous-view.enum'
import { STATUS_HTTP } from '../../constants/http'
import { Helmet } from 'react-helmet-async'

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())
}

export function PlanDetailPage(): JSX.Element {
  const refStatusFetchPlan = useRef(STATUS_HTTP.clean)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const routeParams = useParams()

  const searchParamChannelId = searchParams.get('id')
  const routeParamPlanId = routeParams.id
  const planId = channelIdToPlanId(searchParamChannelId || routeParamPlanId)

  const [fetchPlan, { loading, error, data }] = useLazyQuery<{ plans: PlansQueries }>(getPlan, {
    variables: { id: planId },
  })

  const handleChatOpen = useCallback(function _handleOpenChat() {
    setIsChatOpen(true)
  }, [])

  const handleChatClose = useCallback(function _handleOpenChat() {
    setIsChatOpen(false)
  }, [])

  const plan = data?.plans?.plan

  useEffect(() => {
    if (planId == null) {
      refStatusFetchPlan.current = STATUS_HTTP.done
      console.warn(`Expecting an 'id' url param...redirecting to the home page`, {
        searchParams,
        useParams: routeParams,
      })
      navigate(feedJoinedPathname)
    } else if (refStatusFetchPlan.current !== STATUS_HTTP.pending) {
      refStatusFetchPlan.current = STATUS_HTTP.pending
      fetchPlan({ variables: { id: planId } }).finally(() => {
        refStatusFetchPlan.current = STATUS_HTTP.done
      })
    }
  }, [fetchPlan, navigate, planId])

  const inviterPlanUserId = searchParams.get('inviter')

  useEffect(() => {
    if (planId == null || !plan?.title) {
      return
    }
    const aPreviousView = location.state?.previousView
    const eventData = {
      plan_id: planId,
      plan,
      plan_title: plan?.title,
      did_navigate_from_more_plans_section: aPreviousView === PreviousView.morePlansForYou,
      previous_view: aPreviousView,
      inviter_plan_user_id: inviterPlanUserId,
    }
    createMixpanelEvent(MixpanelEvent.viewPlan, eventData)
  }, [location, plan, planId, inviterPlanUserId])

  const hasError = !planId || !plan || error

  if (loading) {
    return (
      <div className="Large-padding">
        <Loader />
      </div>
    )
  }

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>
          {plan?.title ? toTitleCase(plan?.title).trim() : ''}{' '}
          {plan?.place?.venue ? `@ ${plan?.place?.venue.trim()}` : ''}
          {plan?.startTimeText ? `, ${plan?.startTimeText}` : ''} | Pie
        </title>
        <meta property="og:title" content={plan?.title} />
        <meta name="twitter:title" content={plan?.title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pie" />
        <meta name="description" content={plan?.description ?? ''} />
        <meta property="og:description" content={plan?.description ?? ''} />
        <meta name="twitter:description" content={plan?.description ?? ''} />
        <meta property="og:image" content={plan?.imageUrl ?? ''} />
        <meta property="og:image:secure_url" content={plan?.imageUrl ?? ''} />
        <meta name="twitter:image" content={plan?.imageUrl ?? ''} />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      {hasError ? (
        <Error />
      ) : isChatOpen ? (
        <ChannelChat plan={plan} isChatOpen={isChatOpen} handleChatBackButton={handleChatClose} />
      ) : (
        <div className="PlanDetailPage">
          <PlanDetail plan={plan} handleOpenChat={handleChatOpen} />
          <MorePlans plan={plan} />
        </div>
      )}
    </>
  )
}
