import { useSelector } from 'react-redux'
import { AscDesc, StreamChat, User } from 'stream-chat'
import Loader from '../Loader/Loader'
import { streamApiKey } from '../../constants/constants'
import {
  Chat,
  ChannelList,
  MessageList,
  MessageInput,
  Channel,
  Window,
  ChannelHeader,
  InfiniteScroll,
} from 'stream-chat-react'
import './ChatList.scss'
import Header from '../Header/Header'
import { CustomMessageInput } from '../Chat/CustomMessageInput'
import { useState } from 'react'
import BackIcon from '../../assets/svg/BackIcon.svg'
import type { ChannelOptions } from 'stream-chat'

export const ChatList = () => {
  const [isHiddenList, setIsHiddenList] = useState(false)
  const user = useSelector((state: any) => state.user as User)
  const userLoading = useSelector((state: any) => state.userLoading as boolean)
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })
  if (userLoading) return <Loader />

  const streamChatClient: StreamChat = StreamChat.getInstance(`${streamApiKey}`)

  const filters = { members: { $in: [user?.id] } }
  const options = { limit: 10 }
  const sort = { last_message_at: -1 as AscDesc }
  const optionsMobile: ChannelOptions = { state: true, presence: true, limit: 15 }

  const handleHideChat = () => {
    setIsHiddenList(false)
  }

  const handleHideList = () => {
    setIsHiddenList(true)
  }

  return (
    <>
      {!isHiddenList && <Header />}
      <div className="Flex Flex--centered">
        <div
          className={`ChatList Card Flex Flex--col Flex--fill ${isMobile && !isHiddenList ? 'Overflow-hidden' : ''}`}
        >
          <Chat client={streamChatClient}>
            <div className="ChatList__container">
              {isMobile ? (
                // Display only List or View based on the isMobile condition
                isHiddenList ? (
                  <div className="ChatList__container__chat-view">
                    <Channel>
                      <Window>
                        <div className="ChatList__container__header">
                          <div className="ChatList__container__header__back-button">
                            <img src={BackIcon} alt="Back-icon" onClick={handleHideChat} />
                          </div>
                          <ChannelHeader />
                        </div>
                        <MessageList hideDeletedMessages disableQuotedMessages messageActions={[]} />
                        <MessageInput Input={CustomMessageInput} grow={true} />
                      </Window>
                    </Channel>
                  </div>
                ) : (
                  <div className="ChatList__container__channels-list" onClick={handleHideList}>
                    <h1 className="Pt-6 Pl-6 Pb-3">Messages</h1>
                    <ChannelList filters={filters} options={optionsMobile} sort={sort} Paginator={InfiniteScroll} />
                  </div>
                )
              ) : (
                // Display both List and View in non-mobile view
                <>
                  <div className="ChatList__container__channels-list">
                    <h1 className="Pt-6 Pl-6 Pb-3">Messages</h1>
                    <ChannelList filters={filters} options={options} sort={sort} customActiveChannel="" />
                  </div>
                  <div className="ChatList__container__chat-view">
                    <Channel>
                      <Window>
                        <ChannelHeader />
                        <hr className="Mt-1" />
                        <MessageList hideDeletedMessages messageActions={[]} />
                        <MessageInput Input={CustomMessageInput} grow={true} />
                      </Window>
                    </Channel>
                  </div>
                </>
              )}
            </div>
          </Chat>
        </div>
      </div>
    </>
  )
}
