import './ManageChannelMembers.scss'
import { Plan, PlanUser, RsvpType } from '../../../../__generated__/graphql'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { planUsersFindPlanMembersByRsvpType } from '../../../../graphql/queries/plan-users-find-plan-members-by-rsvp-type'
import { imageSizer } from '../../../../util/image-sizer'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { planUsersRequestToJoinDecline } from '../../../../graphql/mutations/plan-users-request-to-join-decline'
import { planUsersRequestToJoinAccept } from '../../../../graphql/mutations/plan-users-request-to-join-accept'
import { planUsersFindPlanMembers } from '../../../../graphql/queries/plan-users-find-plan-members'
import Skeleton from 'react-loading-skeleton'

export function ManageChannelMembers({
  plan,
  setManageMembersModalIsOpen,
}: {
  plan: Plan
  setManageMembersModalIsOpen: (manageMembersModalIsOpen: boolean) => void
}) {
  const [selectedTab, setSelectedTab] = useState<RsvpType>(RsvpType.Pending)
  const [loading, setLoading] = useState<boolean>(true)
  const [refetchCounter, setRefetchCounter] = useState<number>(0)
  const [planUsersByRsvp, setPlanUsersByRsvp] = useState<{ [rsvp: string]: PlanUser[] }>({
    [RsvpType.Pending]: [],
    [RsvpType.Accepted]: [],
    [RsvpType.Declined]: [],
  })

  const { refetch } = useQuery(planUsersFindPlanMembersByRsvpType, {
    variables: { planId: plan.id, rsvp: selectedTab },
  })
  useEffect(() => {
    async function fetch(): Promise<void> {
      const [{ data: dataPending }, { data: dataAccepted }, { data: dataDeclined }] = await Promise.all([
        refetch({ rsvp: RsvpType.Pending }),
        refetch({ rsvp: RsvpType.Accepted }),
        refetch({ rsvp: RsvpType.Declined }),
      ])
      if (dataPending && dataAccepted && dataDeclined) {
        setLoading(false)
        setPlanUsersByRsvp({
          [RsvpType.Pending]: dataPending?.planUsers?.find?.items || [],
          [RsvpType.Accepted]: dataAccepted?.planUsers?.find?.items || [],
          [RsvpType.Declined]: dataDeclined?.planUsers?.find?.items || [],
        })
      }
    }
    fetch()
  }, [selectedTab, refetchCounter])

  const [planUsersRequestToJoinAcceptMutation] = useMutation(planUsersRequestToJoinAccept)
  const [planUsersRequestToJoinDeclineMutation] = useMutation(planUsersRequestToJoinDecline)

  const setPlanUserRsvpAndCloseDropdown = (planUser: PlanUser, rsvp: RsvpType) => {
    if (rsvp === RsvpType.Accepted && planUser.rsvp !== rsvp) {
      planUsersRequestToJoinAcceptMutation({
        variables: { planId: plan.id, userId: planUser.userId },
        refetchQueries: [planUsersFindPlanMembers, planUsersFindPlanMembersByRsvpType],
      })
        .then(() => {
          setRefetchCounter(refetchCounter + 1)
        })
        .catch((err) => {
          console.error(`Error accepting user request to join plan ${plan.id}`, { plan, err, planUser })
        })
    } else if (rsvp === RsvpType.Declined && planUser.rsvp !== rsvp) {
      planUsersRequestToJoinDeclineMutation({
        variables: { planId: plan.id, userId: planUser.userId },
        refetchQueries: [planUsersFindPlanMembers, planUsersFindPlanMembersByRsvpType],
      })
        .then(() => {
          setRefetchCounter(refetchCounter + 1)
        })
        .catch((err) => {
          console.error(`Error declining user request to join plan ${plan.id}`, { plan, err, planUser })
        })
    }
  }

  return (
    <div className="ManageChannelMembers Flex Flex--col Flex--align-center G-3">
      <h3>Manage Requests</h3>
      <div className="ManageChannelMembers__tabs Flex Flex--centered G-3">
        <div className="ManageChannelMembers__tabs__item" onClick={() => setSelectedTab(RsvpType.Pending)}>
          <h3 className="ManageChannelMembers__tabs__item__text Px-2 Py-3">
            {loading ? '...' : planUsersByRsvp[RsvpType.Pending].length} Pending
          </h3>
          {selectedTab === RsvpType.Pending && <div className="ManageChannelMembers__tabs__item__bottom-border"></div>}
        </div>
        <div className="ManageChannelMembers__tabs__item" onClick={() => setSelectedTab(RsvpType.Accepted)}>
          <h3 className="ManageChannelMembers__tabs__item__text Px-2 Py-3">
            {loading ? '...' : planUsersByRsvp[RsvpType.Accepted].length} Accepted
          </h3>
          {selectedTab === RsvpType.Accepted && <div className="ManageChannelMembers__tabs__item__bottom-border"></div>}
        </div>
        <div className="ManageChannelMembers__tabs__item" onClick={() => setSelectedTab(RsvpType.Declined)}>
          <h3 className="ManageChannelMembers__tabs__item__text Px-2 Py-3">
            {loading ? '...' : planUsersByRsvp[RsvpType.Declined].length} Declined
          </h3>
          {selectedTab === RsvpType.Declined && <div className="ManageChannelMembers__tabs__item__bottom-border"></div>}
        </div>
      </div>
      {loading && (
        <>
          <Skeleton containerClassName="ManageChannelMembers__loading-skeleton" className="Fill-width Fill-height" />
          <Skeleton containerClassName="ManageChannelMembers__loading-skeleton" className="Fill-width Fill-height" />
        </>
      )}
      {!loading && (
        <>
          <div className="Fill-width">
            {planUsersByRsvp[selectedTab].length === 0 && (
              <p className="Gray-text Mt-12 Text-centered">{`No ${selectedTab} requests yet.`}</p>
            )}
            {planUsersByRsvp[selectedTab].map((planUser) => {
              return (
                <div
                  key={planUser.id}
                  className="ManageChannelMembers__user Flex Flex--space-between Flex--align-center G-3"
                >
                  <div className="Flex Flex--align-center G-3">
                    <img
                      className="ManageChannelMembers__user__image"
                      src={planUser.user.imageUrl ? imageSizer({ url: planUser.user.imageUrl, width: 300 }) : undefined}
                      alt={planUser.user.firstName || 'Profile Picture'}
                    />
                    <div>
                      <p>{planUser.user.fullName}</p>
                    </div>
                  </div>
                  <DropdownButton className="ManageChannelMembers__user__dropdown" title="Select...">
                    <Dropdown.Item
                      as="button"
                      className="ManageChannelMembers__user__dropdown__menu__item"
                      onClick={() => setPlanUserRsvpAndCloseDropdown(planUser, RsvpType.Accepted)}
                    >
                      Accepted
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      className="ManageChannelMembers__user__dropdown__menu__item"
                      onClick={() => setPlanUserRsvpAndCloseDropdown(planUser, RsvpType.Declined)}
                    >
                      Declined
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )
            })}
          </div>
        </>
      )}
      <button
        className="Button-primary Button-large Fill-width Mt-12"
        onClick={() => setManageMembersModalIsOpen(false)}
      >
        Done
      </button>
    </div>
  )
}
