import dayjs from 'dayjs'
import './PlanDate.scss'

export const PlanDate = ({
  date,
  timeText,
  timezone = 'UTC',
}: {
  date: Date
  timeText: string | null
  timezone?: string | null
}) => {
  const invalidTimeText = timeText === 'Invalid Date' || timeText === null
  return (
    <div className="PlanDate">
      <i className="bi bi-calendar PlanDate__icon"></i>
      <div className="PlanDate__container">
        <h5>
          {dayjs(date)
            .tz(timezone ?? 'UTC')
            .format('dddd, MMM D')}
        </h5>
        {timeText && !invalidTimeText && <div>{timeText}</div>}
      </div>
    </div>
  )
}
