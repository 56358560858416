import { useQuery } from '@apollo/client'
import { Plan, PlansQueries, User } from '../../../__generated__/graphql'
import { plansFindShared } from '../../../graphql/queries/plans-find-shared'
import { PlanCard } from '../../PlanCard/PlanCard'
import Loader from '../../Loader/Loader'
import Error from '../../Error/Error'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useState } from 'react'
import { SkeletonLoaderPlanCard } from '../../SkeletonLoaderPlanCard/SkeletonLoaderPlanCard'

export const ProfileSharedPlans = ({ userProfile }: { userProfile: User }) => {
  const [futurePlans, setFuturePlans] = useState([] as Plan[])
  const [pastPlans, setPastPlans] = useState([] as Plan[])
  const limit = 10

  const { error, loading } = useQuery<{ plans: PlansQueries }>(plansFindShared, {
    variables: {
      otherUserId: userProfile.id,
      limit,
      offset: 0,
      showFuturePlans: true,
    },
    onCompleted: (data) => setFuturePlans(data.plans.findShared.items),
  })
  const { error: pastError, loading: pastLoading } = useQuery<{ plans: PlansQueries }>(plansFindShared, {
    variables: {
      otherUserId: userProfile.id,
      limit,
      offset: 0,
      showFuturePlans: false,
    },
    onCompleted: (data) => setPastPlans(data.plans.findShared.items),
  })

  if (error || pastError) {
    console.error('Unexpected plans findShared response', {
      otherUserId: userProfile.id,
      limit,
      futurePlans,
      pastPlans,
      error,
    })
    return <Error />
  }

  if (loading || pastLoading) return <SkeletonLoaderPlanCard />

  if (futurePlans.length === 0 && pastPlans.length === 0) {
    return null
  }

  return (
    <>
      <InfiniteScroll
        dataLength={futurePlans.length + pastPlans.length}
        next={() => {}}
        hasMore={false}
        loader={<Loader />}
      >
        {futurePlans.length > 0 && (
          <>
            <hr />
            <h2 className="Mb-2">
              {futurePlans.length > 0
                ? `${futurePlans.length} plan${futurePlans.length > 1 ? 's' : ''} in common`
                : 'No plans in common'}
            </h2>
            {futurePlans.map((plan) => (
              <PlanCard plan={plan} key={plan.id} isMorePlansSection={false} />
            ))}
          </>
        )}
        {pastPlans.length > 0 && (
          <>
            <h2 className="Mb-2">Plans you both went to</h2>
            {pastPlans.map((plan) => (
              <PlanCard plan={plan} key={plan.id} isMorePlansSection={false} />
            ))}
          </>
        )}
      </InfiniteScroll>
    </>
  )
}
