import { Plan, PlanLink, RsvpType, User } from '../../../__generated__/graphql'
import { ChannelDates } from '../ChannelDates/ChannelDates'
import { ChannelDetail } from '../ChannelDetail/ChannelDetail'
import { ChannelPlace } from '../ChannelPlaces/ChannelPlace'
import Link from '../../../assets/svg/Link.svg'
import './ChannelHeader.scss'
import { useSelector } from 'react-redux'

export const ChannelHeader = ({
  plan,
  setSignUpAndRsvpModalIsOpen,
}: {
  plan: Plan
  setSignUpAndRsvpModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })
  const user = useSelector((state: any) => state.user as User)

  const planLinks: PlanLink[] = plan?.links || []
  const didRsvpYesOrMaybe = plan.currentUserRsvp && [RsvpType.Yes, RsvpType.Maybe].includes(plan.currentUserRsvp)
  const planOwnerUserName = plan.ownerUser.firstName
  const isPlanCreator = plan.ownerUserId === user?.id

  if (isMobile) {
    return (
      <div className="ChannelHeader">
        <div className="ChannelHeader__creator">
          <img className="ChannelHeader__creator__image" src={plan.ownerUser.imageUrl || ''} />
          {isPlanCreator ? (
            <p className="Subtitle">You created</p>
          ) : (
            <p className="Subtitle">{planOwnerUserName} created</p>
          )}
        </div>
        <div className={didRsvpYesOrMaybe ? 'ChannelHeader__picture-title' : ''}>
          <img
            className={
              didRsvpYesOrMaybe ? 'ChannelHeader__image ChannelHeader__image--joined-plan' : 'ChannelHeader__image'
            }
            src={plan.imageUrl ? plan.imageUrl : undefined}
          />

          <h1>{plan.title}</h1>
        </div>
        {!plan.capacity?.atCapacity && (
          <>
            <div className="ChannelHeader__date-place">
              <ChannelDates plan={plan} setSignUpAndRsvpModalIsOpen={setSignUpAndRsvpModalIsOpen} />
            </div>

            <ChannelPlace plan={plan} />

            {plan.description && <ChannelDetail planDescription={plan.description} />}

            {planLinks && planLinks.length !== 0 && (
              <div className="ChannelHeader__links">
                {planLinks.map((link: PlanLink, i: number) => (
                  <a key={i} className="ChannelHeader__links__anchor" href={link.url} target="_blank">
                    <img src={Link} alt="Link" />
                    {link.description || link.url}
                  </a>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="ChannelHeader__creator">
        <img className="ChannelHeader__creator__image" src={plan.ownerUser.imageUrl || ''} />
        {isPlanCreator ? (
          <p className="Primary-text">You created</p>
        ) : (
          <p className="Primary-text">{planOwnerUserName} created</p>
        )}
      </div>
      <div className={didRsvpYesOrMaybe ? 'ChannelHeader--joined-plan' : 'ChannelHeader'}>
        <img
          className={
            didRsvpYesOrMaybe ? 'ChannelHeader__image ChannelHeader__image--joined-plan' : 'ChannelHeader__image'
          }
          src={plan.imageUrl ? plan.imageUrl : undefined}
        />
        <div className="Min-width-0">
          <h1>{plan.title}</h1>
          <div className="ChannelHeader__date-place">
            <ChannelDates plan={plan} setSignUpAndRsvpModalIsOpen={setSignUpAndRsvpModalIsOpen} />
          </div>
          <ChannelPlace plan={plan} />
          {plan.description && <ChannelDetail planDescription={plan.description} />}
          {planLinks && planLinks.length !== 0 && (
            <div className="ChannelHeader__links">
              {planLinks.map((link: PlanLink, i: number) => (
                <a key={i} className="ChannelHeader__links__anchor" href={link.url} target="_blank">
                  <img src={Link} alt="Link" />
                  {link.description || link.url}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
